
window.addEventListener('DOMContentLoaded', event => {

    // Markdown - a function to copy entire article in markdown format
    function copyMarkdown() {
        var markdownTextarea = document.getElementById('markdownContent');
        var markdownText = markdownTextarea.value;
        navigator.clipboard.writeText(markdownText).then(function() {
            alert('Markdown content copied to clipboard');
        }).catch(function(err) {
            console.error('Could not copy text: ', err);
        });
    }

    function downloadMarkdown() {
        var markdownTextarea = document.getElementById('markdownContent');
        var markdownText = markdownTextarea.value;

        var blob = new Blob([markdownText], { type: 'text/markdown' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'article.md';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    var copyButton = document.getElementById('copyButton');
    copyButton.addEventListener('click', copyMarkdown);

    var downloadButton = document.getElementById('downloadButton');
    downloadButton.addEventListener('click', downloadMarkdown);

});
